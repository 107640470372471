
	import Vue from "vue";
	// @ts-ignore
	import CardSwitch from "@/components/Content/CardSwitch.vue";
	import {
		User,
		UserDataCreate,
		UserDataUpdate,
		Role,
	} from "../../../../interfaces/user";
	import { isNaN, last, toNumber } from "lodash";

	import Alertize from "../../../../components/Alertize.vue";
	import { Notification } from "../../../../interfaces/proccess";

	import {
		isRequired,	
		isNumber,	
		isMinLength,
		isMaxLength,
	} from "../../../../services/rule-services";
	import { mapActions, mapGetters } from "vuex";
	// @ts-ignore
	import { TypeLoading } from "@/interfaces/loading";
	//@ts-ignore
	import { getPermisionUser } from "@/utils/permissionResolve.ts";

	export default Vue.extend({
		name: "UserCreate",
		props: {},
		components: { Alertize, CardSwitch },
		data: () => ({
			title: "User Create",
			user: {} as UserDataCreate,
			valid: false,
			loading: false,
			message: "",
			type: "info",

			id: undefined,
			name: "",
			last_name: "",
			email: "",
			role_id: undefined,
			active: true,
		}),
		created() {},
		async mounted() {
			try {
				this.setLoadingData(TypeLoading.loading)
				await this.dispatchRoles().catch((err) => {});
				this.getId;
				this.user = await this.dispatchShowUser();
				this.id = this.user.id;
				this.name = this.user.name;
				this.last_name = this.user.last_name;
				this.email = this.user.email;
				this.role_id = this.user.role_id;
				this.active = this.user.active ? true : false;
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
				console.log("mounted", { error: error });
			}
			this.$refs.form.resetValidation();
		},
		computed: {
			...mapGetters("profile", ["getAbility"]),
			getId() {
				let pathArray: String[] = this.$route.path.split("/");
				const lastItem = last(pathArray);
				this.id = !isNaN(toNumber(lastItem)) ? toNumber(lastItem) : NaN;
			},
			getRoles(): Role[] {
				return this.$store.state.user.roles;
			},
			getUser(): User {
				return this.user;
			},
			isAlertize(): Boolean {
				return this.$store.state.proccess.alertize;
			},
			getRules() {
				return {
					isRequired,
					isNumber,
					isMinLength,
					isMaxLength,
				};
			},
			canActive(){
				return this.getAbility.can(this.getPermission.actions.active, this.getPermission.subject);
			},

			getPermission(){
				return getPermisionUser();
			}
			
		},
		methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("profile", ["fetchProfile"]),

			async validate() {
				let form = this.$refs.form;
				return await form.validate();
			},
			setNotification(notification: Notification) {
				return this.$store.dispatch(
					"proccess/setNotification",
					notification,
					{ root: true }
				);
			},
			async handleSubmit() {
				try {
					if (!(await this.validate())) return;
					this.setLoadingData(TypeLoading.loading)
					await this.update();
					await this.fetchProfile();
					await this.setLoadingData();
				} catch (error) {
					console.error("handleSubmit", { error: error });
					await this.setLoadingData();
				}
			},
			redirectTo() {
				this.setNotification({ title: "", message: "", type: "" });
				this.$router.push({ name: "UsersIndex" });
			},
			toggleStatus(status: boolean) {
				this.active = Boolean(status);
			},
			async dispatchRoles() {
				return this.$store.dispatch("user/listRoles", {
					root: true,
				});
			},
			async dispatchShowUser() {
				return this.$store.dispatch("user/show", this.id, {
					root: true,
				});
			},
			async update() {
				this.user = {
					id: this.id,
					name: this.name,
					last_name: this.last_name,
					email: this.email,
					role_id: this.role_id,
					active: this.active ? 1 : 0,
				} as UserDataUpdate;

				return this.$store.dispatch(
					"user/update",
					{
						user: this.user,
						id: this.id,
					},
					{
						root: true,
					}
				);
			},
		},
	});
